<template>
  <div class="div-clas mb-5" style="margin-top:-5rem !important;">
      <div class="bg-vendemas">
        <div class="container-fluid margin-text-titulo-vendemas">
          <div class="row">
            <img class="img-header-vendemas-movil" style="margin-top:7rem !important;" src="../assets/Nosotros/icono_principal.png" alt="">
            <div class="col-md-5 offset-md-1 mt-5">
              <h1 class="text-titulo-header-vendemas animate__animated  animate__bounceInDown animate__fast "><b>ELLOS YA<br> VENDEN MAS </b></h1>
              <div class="tamaño-div-vendemas"><p class="text-header-vendemas ">Te compartimos los clientes que ya se sumaron a la familia Teschsoft y que ahora han incrementado sus ventas. <b> ¿Y tu, qué esperas para vender más?</b></p></div>
              <div class="left-vendemas">
              <b-button class="button-vendemas animate__animated animate__zoomIn mt-3 mb-5" variant="primary"><span class="text-button-vendemas">Habla con un asesor</span> </b-button>
              </div>  
            </div>
            <div class="col-md-6">
              <img class="img-header-vendemas mb-5" style="margin-top:5rem !important;" src="../assets/Nosotros/icono_principal.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <img class="img-vendemas-movil" src="../assets/img-techsoft/techsoft-recarga.png" alt="">
          <div class="div-vendemas">
            <h1 class="text-titulo-vendemas"><b>Zona de Recargas</b></h1>
            <p class="p-vendemas">Plataforma para empresa mexicana. Se trata de un software administrativo desarrollado para una empresa de telecomunicaciones ubicada en Mérida. Esta plataforma administra todas las ventas, empleados, y todos los bienes de la empresa. Cuenta con más de 480 mil transacciones al mes. Esta plataforma automatiza ventas por internet y Whatsapp. Así como también está integrada con Codi, el sistema de pagos expedido por Banco de México.</p>
          </div>
        </div>
        <img class="img-fluid img-web-nosotros" src="../assets/img-techsoft/techsoft-recarga.png" alt=""><hr class="linea mt-5"> 
      </div>
      <div class="container-fluid">
        <div class="row">
          <img class="img-inmobina-movil img-fluid" src="../assets/img-techsoft/inmobina.png" alt="">
          <div class="col-md-7 div-vendemas text-align-l-vendemas">
              <h1 class="text-titulo-vendemas"><b> Inmobina </b></h1>
              <p class="p-vendemas mt-4">Es una plataforma digital enfocada en la formación en bienes raices mediante la venta de cursos online. La empresa está dirigida a personas que busquen incrementar sus ingresos exponencialmente o empezar desde cero en la industria inmobiliaria.</p>
              <p class="p-vendemas ">Desarrollamos su plataforma con base en las necesidades de la empresa y del usuario final.</p>
              <label class="p-vendemas ">Te compartimos algunos de sus atributos:</label>
              <label class="p-vendemas ">Conexión a pagos con paypal y Stripe.</label>
              <label class="p-vendemas ">Acceso a reportes de ventas</label>
              <label class="p-vendemas ">Visualización de información de usuarios</label>
              <label class="p-vendemas ">Multiusuario</label>
              <label class="p-vendemas ">Login con redes sociales</label>
          </div>
          <div class="col-md-4">
            <div class=""><img class="img-inmobina img-fluid" src="../assets/img-techsoft/inmobina.png" alt=""></div>
          </div><hr class="linea mt-5"> 
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">  
          <div class="div-vendemas">
            <img class="img-fluid img-modo-organico-movil" src="../assets/img-techsoft/mo-pc2.png" alt="">
            <h1 class="text-titulo-vendemas"><b>Modo Orgánico</b></h1>
            <p class="p-vendemas">Es una empresa ubicada en Perú que fomenta un estilo de vida saludable y consciente con el planeta. Modo Orgánico ofrece productos altamente nutritivos, ricos y que sean fáciles de incorporar en el estilo de vida de cada uno. Implementan tecnología que asegura la extracción perfecta del insumo para así aprovechar todos sus componentes nutritivos.</p>
          </div>
        </div>
        <img class="img-fluid img-modo-organico" src="../assets/img-techsoft/mo-pc2.png" alt="">
      </div><hr class="linea mt-5"> 
      <div class="container-fluid">
        <div class="row">
          <img class="img-inmobina-movil img-fluid" src="../assets/img-techsoft/phone-mood n.png" alt="">
          <div class="col-md-7 div-vendemas text-align-l-vendemas">
              <h1 class="text-titulo-vendemas"><b> MOOD N THE HOOD </b></h1>
              <p class="p-vendemas mt-4">Es una marca 100% mexicana de jóvenes emprendedores, los cuales tienen la intención de crear un movimiento social, el cual se sustenta por la venta de artículos en tendencia. Su proposito es brindar al comprador la oportunidad de expresarse de una manera única e inigualable: a través de ropa.</p>
              <p class="p-vendemas ">Su fin no es vender, sino crear. Crear impacto, conciencia y confianza en la sociedad. Buscan tener un alcance y romper con todos los paradigmas de lo que ya esta escrito, debido a lo anterior, es una marca con propósito, sin género, talla y completamente libre de estereotipos.</p>
          </div>
          <div class="col-md-4">
            <div class=""><img class="img-inmobina img-fluid" src="../assets/img-techsoft/phone-mood n.png" alt=""></div>
          </div><hr class="linea mt-5"> 
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <img class="img-skin-movil" src="../assets/img-techsoft/pc-img-2.png" alt="">
          <div class="div-vendemas">
            <h1 class="text-titulo-vendemas"><b>Vendo Skincare</b></h1>
            <p class="p-vendemas">Plataforma para empresa mexicana. Se trata de un software administrativo desarrollado para una empresa de telecomunicaciones ubicada en Mérida. Esta plataforma administra todas las ventas, empleados, y todos los bienes de la empresa. Cuenta con más de 480 mil transacciones al mes. Esta plataforma automatiza ventas por internet y Whatsapp. Así como también está integrada con Codi, el sistema de pagos expedido por Banco de México.</p>
          </div>
        </div>
      </div>
      <img class="img-fluid img-web-skin" src="../assets/img-techsoft/pc-img-2.png" alt=""><hr class="linea mt-5"> 
      <div class="container-fluid">
        <div class="row">
          <img class="img-inmobina-movil img-fluid" src="../assets/img-techsoft/phone-nature.png" alt="">
          <div class="col-md-7 div-vendemas text-align-l-vendemas">
              <h1 class="text-titulo-vendemas"><b>Nature Lab</b></h1>
              <p class="p-vendemas mt-4">Es una empresa reconocida que maneja una línea extensa de Suplementos Alimenticios que contienen Vitaminas, Minerales y Extractos Naturales.</p>
              <p class="p-vendemas ">Manejan productos de la mejor calidad que son 100% No - GMO y libres de gluten y sabores artificiales, para satisfacer sus necesidades individuales y un estilo de vida activo.</p>
              <p class="p-vendemas ">Manejan productos para toda la familia, otros especializados en mujeres y para niños manejan vitaminas en presentación de gomitas frutales.</p>
          </div>
          <div class="col-md-4">
            <div class=""><img class="img-inmobina img-fluid" src="../assets/img-techsoft/phone-nature.png" alt=""></div>
          </div><hr class="linea mt-5"> 
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <img class="img-skin-movil" src="../assets/img-techsoft/corporativo-pc.png" alt="">
          <div class="div-vendemas">
            <h1 class="text-titulo-vendemas"><b>Corporativo A y G</b></h1>
            <p class="p-vendemas">Se conforma por una firma de contadores públicos y abogados fiscalistas expertos en registros contables, asesoría fiscal integral, defensa fiscal, dictamen y certificación de cumplimiento de protocolos sanitarios y diseño corporativo.</p>
          </div>
        </div>
      </div>
      <img class="img-fluid img-web-skin" src="../assets/img-techsoft/corporativo-pc.png" alt=""><hr class="linea mt-5"> 
      <div class="container-fluid">
        <div class="row">
          <img class="img-inmobina-movil img-fluid" src="../assets/img-techsoft/phone-qr.png" alt="">
          <div class="col-md-7 div-vendemas text-align-l-vendemas">
              <h1 class="text-titulo-vendemas"><b>Menú QR</b></h1>
              <p class="p-vendemas mt-4">Es un software para restaurantes, bares y cafeterías adecuado a la Nueva Normalidad. Al escanear un código QR impreso en la mesa del establecimiento, el usuario puede ver el menú, ordenar y pagar sin necesidad de tener contacto con cartas físicas o personal. Este permite vender tanto de manera local como a domicilio. </p>
              <p class="p-vendemas ">Cuenta con la realización de facturas, administración de empleados en el restaurante, todo adecuado a un restaurante en la Nueva Normalidad.</p>
          
            </div>
          <div class="col-md-4">
            <div class=""><img class="img-inmobina img-fluid" src="../assets/img-techsoft/phone-qr.png" alt=""></div>
          </div><hr class="linea mt-5"> 
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <img class="img-skin-movil" src="../assets/img-techsoft/defended-pc.png" alt="">
          <div class="div-vendemas">
            <h1 class="text-titulo-vendemas"><b>Defend - X1</b></h1>
            <p class="p-vendemas">Es una empresa que vende un poderoso agente antibacterial formulado para usarse en las superficies, elimina el 99% de las bacterias y de los virus. Este producto se vende en diferentes presentaciones.</p>
          </div>
        </div>
      </div>
      <img class="img-fluid img-web-skin" src="../assets/img-techsoft/defended-pc.png" alt=""><hr class="linea mt-5"> 
      <div class="container-fluid">
        <div class="row">
          <img class="img-inmobina-movil img-fluid" src="../assets/img-techsoft/pc-binslab.png" alt="">
          <div class="col-md-7 div-vendemas text-align-l-vendemas">
              <h1 class="text-titulo-vendemas"><b>BlinLab</b></h1>
              <p class="p-vendemas mt-4">Es una joven empresa mexicana que propone una nueva generación de afrodisíacos. La empresa se preocupa porque sus clientes tengan vidas más felices gracias a una mejor vida sexual. </p>
              <p class="p-vendemas ">Los productos naturistas que ofrecen van desde afrodisíacos naturales, tratamientos para la eyaculación precoz, lubricantes vaginales, lubricantes anales, entre otros.</p>      
            </div>
          <div class="col-md-4 mb-5">
            <div class=""><img class="img-inmobina img-fluid" src="../assets/img-techsoft/pc-binslab.png" alt=""></div>
          </div>
        </div>
      </div>
  </div> 
</template>

<script>
export default {

}
</script>

<style>
.img-inmobina-movil{
  display: none;
}
.img-modo-organico{
  display: inline;
}
.img-modo-organico-movil{
  display: none;
}
.img-web-nosotros{
  display: inline;
}
.img-web-skin{
  display: inline;
  width: 350%;
}
.img-vendemas-movil{
  display: none;
}
.img-skin-movil{
  display: none;
}
.f-vendemas{
  height: 500px;
  margin-bottom: 20%;
  margin-top: -1%;
}

.bg-vendemas { 
  /* The image used */
  background-image: url("../assets/Inicio/principal.png") !important;
  margin-top: 5%;
  height: 130%; 


  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.div1-25{
  background-image: url("../assets/Inicio/creacion_pagina_web.png");
   width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

}
.div2-25{
  background-image: url("../assets/Inicio/desarrollo_plataformas.png");
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;


}
.div3-25{
  background-image: url("../assets/Inicio/chatbot_wa.png");
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;


}
.div4-25{
  background-image: url("../assets/Inicio/pixel_facebook.png");
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;


}
.div5-25{
  background-image: url("../assets/Inicio/campanas_fb_ga.png");
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;


}
.div6-25{
  background-image: url("../assets/Inicio/publicacion_redes.png");
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;


}
.img-header-vendemas-movil{
    display: none;
  }
  .img-header-vendemas{
    display: inline;
  }

@-webkit-keyframes mover2 {
    1% { transform: translateX(1px); }
    50% { transform: translateY(-15px); }
}

@media only screen and (max-width: 770px) {
    .img-header-vendemas-movil{
      display: inline;
      width: 40%;
      margin-top: 8%;
      margin-bottom: 3%;
      margin-left: 30%;
    }
    .img-header-vendemas{
    display: none;
    }
    .text-titulo-header-vendemas{
      color: rgb(255, 255, 255);
      font-size: 47px !important;
      width: 100%;
      text-align: center;

    }
    .text-header-vendemas{
      color: rgb(255, 255, 255);
      font-size: 20px !important ;
      text-align: justify;
      padding: 5%;
      margin-top: 0px;
    }
    .button-vendemas{
      width: 75%;
      height: 10%;
      margin-bottom: 15%;
      margin-left: 13%;
    }
    .text-button-vendemas{
      font-size:15px !important;
    }
    .z-cuadros-25k{
      height:400px !important;
      margin-top:5%;
      margin-left: 1px;
    
    }

    .text-cuadros-25k{
      color:white;
      display:block; 
      margin: 0 auto; 
      font-size:1.6rem !important;
    }
    .text-titulo-vendemas{
      font-size: 1.6rem !important;
        text-align: center;
    }
    .img-web-nosotros{
      display: none;
    }
    .img-web-skin{
      display: none;
    }
    .img-vendemas-movil{
      display: inline;
      margin-top: 3%;
      margin-bottom: 3%;
      width: 100%;
      height: 150%;
    }
    .img-skin-movil{
      display: inline;
      margin-top: 3%;
      margin-bottom: 3%;
      width: 100%;
      height: 150%;
    }
    .p-vendemas{
        font-size: 1.2rem !important;
        width: 100%;
      }
      .div-vendemas {
        margin-left: 5%;
        margin-right: 5%;
      }
      .img-modo-organico{
      display: none;
    }
    .img-modo-organico-movil{
      display: inline;
    }
    .img-inmobina{
      display: none;
    }
    .img-inmobina-movil{
      display: inline;
      width: 40%;
      margin-top: 5%;
      margin-bottom: 5%; 
      margin-left: 30%;
    }
    
    .div-vendemas{
  margin-left: 5%;
}
}
@media only screen and (max-width: 1070px) and (min-width: 720px) {
  .text-titulo-header-vendemas, .img-header-vendemas{
    margin-top: 3rem !important;
    
  }
  
}
</style>